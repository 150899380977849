import React from 'react';
import profile from './profile.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <span className="img-circle"><img src={profile} width={200} alt="Characature of Stuart Elmore" /></span>
        <h1>Hello I'm <em>Stuart</em>.</h1>
      </header>
      <main>
        <p>I work for <a target="_blank" rel="noopener noreferrer" href="https://apple.com">Apple</a> as development lead for <a href="https://www.apple.com/accessibility/" target="_blank" rel="noopener noreferrer">Accessibility and SEO Tools</a>.</p>
        <p>You can find me on <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/digitalgravy">Twitter</a>,{' '} 
          <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/digitalgravy">Instagram</a>,{' '} 
          <a target="_blank" rel="noopener noreferrer" href="https://github.com/digitalgravy">Github</a>{' '}
          and <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/digitalgravy">LinkedIn</a>.</p>
      </main>
    </div>
  );
}

export default App;
